import React from 'react';

const ServicesSalesforce = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="30"
    height="22"
    viewBox="0 0 30 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.99905 11C8.99905 9.02219 9.58554 7.08879 10.6844 5.4443C11.7832 3.79981 13.345 2.51809 15.1722 1.76121C16.9995 1.00433 19.0101 0.806299 20.95 1.19215C22.8898 1.578 24.6716 2.53041 26.0701 3.92894C27.4686 5.32746 28.4211 7.10929 28.8069 9.0491C29.1928 10.9889 28.9947 12.9996 28.2378 14.8268C27.481 16.6541 26.1992 18.2159 24.5548 19.3147C22.9103 20.4135 20.9769 21 18.9991 21H7.99905C7.00635 20.999 6.0252 20.7869 5.12074 20.3778C4.21628 19.9686 3.40918 19.3718 2.75303 18.6269C2.09687 17.8819 1.60666 17.006 1.31494 16.0571C1.02321 15.1082 0.936647 14.1081 1.06098 13.1233C1.18532 12.1384 1.51771 11.1912 2.03611 10.3446C2.5545 9.498 3.24703 8.77134 4.06773 8.21285C4.88844 7.65437 5.81854 7.27683 6.79631 7.1053C7.77408 6.93377 8.77716 6.97217 9.73896 7.21794"
      stroke="#344054"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ServicesSalesforce;
